<template>
  <div class="shelf-detail" v-loading="loading">
    <div class="shelf-detail-info">
      <div class="shelf-detail-info-title">
        <div class="zh_title">货架信息</div>
        <div :class="['shelf-detail-info-status-' + ( shelfDetail.status ? 1 : 0 ) ,'shelf-detail-info-status','ml_8']">
          {{ shelfDetail.status ? '开启' : '禁用' }}
        </div>
      </div>
      <div class="shelf-detail-info-control">
        <el-button @click="edit" type="primary" class="shelf-detail-info-control-update">编辑</el-button>
        <el-button class="shelf-detail-info-control-qrcode" @click="printQrcode">
          <img src="@/assets/img/index/qrcode-down.png" alt="" class="shelf-detail-info-control-qrcode-img"/>
          货架二维码打印
        </el-button>
      </div>
    </div>
    <div class="shelf-detail-info shelf-detail-info-detail mt_8">
      <div class="shelf-detail-info-detail-item">
        <more-text title="仓库名称" :text="shelfDetail.stockName"></more-text>
      </div>
      <div class="shelf-detail-info-detail-item">
        <more-text title="详细地址" :text="shelfDetail.stockAddress"></more-text>
      </div>
      <div class="shelf-detail-info-detail-item">
        <more-text title="货架名称" :text="shelfDetail.shelfName"></more-text>
      </div>
      <div class="shelf-detail-info-detail-item">
        <more-text title="备注" :text="shelfDetail.remark"></more-text>
      </div>
    </div>
    <div class="zh_title mt_20">货架列表</div>
    <div class="shelf-detail-child-list mt_16">
      <el-scrollbar :height="height-310" always>
        <div class="shelf-child-list-content">
          <shelfList :shelf-info="shelfDetail">
            <template #default="{data}">
              <div class="shelf-child-list-content-item">
                <div class="shelf-child-list-content-item-title">{{ data.childShelfName }}</div>
                <div class="shelf-child-list-content-item-count mt_16">
                  <div class="type">
                    <span>产品种类：</span>
                    <span class="count">{{ data.productCategoryCount }}</span>
                  </div>
                  <div class="type ml_8">
                    <span>产品数量：</span>
                    <span class="count">{{ data.productCount }}</span>
                  </div>
                </div>
                <div class="shelf-child-list-content-item-btn mt_8" @click="viewDetail(data)">查看详情</div>
              </div>
            </template>
          </shelfList>
        </div>
      </el-scrollbar>
    </div>
    <childShelfProduct :shelf-detail="childShelfInfo" v-model="showModal"></childShelfProduct>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import useShelfDetail from "@utils/hooks/shelf/useShelfDetail";
import MoreText from "@components/moreText/index.vue";
import shelfList from "@views/personal/shelfManagement/component/shelfList/index.vue";
import {useViewPort} from "@utils/tool/useVIewPort";
import childShelfProduct from "@views/personal/shelfManagement/component/detail/component/childShelfProducModal.vue";
import useModal from "@/utils/hooks/useModal";
import useChildShelf from "@/utils/hooks/shelf/useChildShelf";
import {useStorage} from '@vueuse/core'

const {childShelfInfo, setChildShelfInfo} = useChildShelf()
const {showModal, handleOpenModal} = useModal()
const {height} = useViewPort()
const loading = ref(false)
const route = useRoute()
const router = useRouter()
const {shelfDetail, getShelfDetail} = useShelfDetail()
const shelfId = +route.query.id || 0
const stockId = +route.query.stockId || 0

onMounted(async () => {
  loading.value = true
  await getShelfDetail({shelfId, stockId})
  loading.value = false
})

//编辑
const edit = () => {
  router.push({
    path: '/personal/shelfManagement/update',
    query: {
      id: shelfId,
      stockId
    }
  })
}

//查看详情
const viewDetail = (e) => {
  setChildShelfInfo(e)
  handleOpenModal()
}

//货架二维码打印
const printQrcode = () => {
  const detail = useStorage('shelf-child', shelfDetail)
  detail.value = shelfDetail
  let url = `/personal/shelfManagement/shelfChild/print`
  window.open(url)
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shelfDetail",
});
</script>

<style lang="scss" scoped>
@import "../../common";
@import "./index.scss";
</style>
