<template>
  <el-dialog
      :title="title"
      :model-value="modelValue"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDia"
      destroy-on-close
      width="1400"
      align-center
      :before-close="closeDia"
  >
    <div class="child-shelf-product-modal" :style="{'--h':height}">
      <el-table
          :data="childShelfProductList"
          style="width: 100%"
          height="calc(var(--h) * 1px - 260px)"
          class="zh-no-table-border"
          :row-class-name="tableRowClassNameNew"
          :cell-style="{ border: 'none' }"
          :header-row-style="{ border: 'none' }"
          :span-method="objectSpanMethod([0,1,2,3,4])"
      >
        <el-table-column
            width="100"
            label="序号"
            align="center"
            fixed
        >
          <template #default="{row}">
            <div class="table_text">{{ row.realIndex + 1 }}</div>
          </template>
        </el-table-column>
        <template v-for="item in titleList" :key="item.fieldKey">
          <el-table-column
              v-if="item.fieldKey === 'unitName'"
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :prop="item.fieldKey"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
          >
            <template #default="{row}">
              <div v-html="getUnitName(row)"></div>
            </template>
          </el-table-column>
          <el-table-column
              v-else
              align="center"
              :label="item.title"
              :prop="item.fieldKey"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
          >
            <template #default="scope">
              <div v-html="scope.row[item.fieldKey] || '--'"></div>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="mainPage">
        <el-pagination
            v-model:current-page="pageInfo.pageNumber"
            layout="total, prev, pager, next , jumper"
            :total="pageInfo.total"
            v-model:page-size="pageInfo.pageSize"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import {computed, toRefs} from "vue";
import useChildShelf from "@/utils/hooks/shelf/useChildShelf";
import {useViewPort} from "@/utils/tool/useVIewPort";
import {getUnitName} from "@utils/tool/orderTools";
import {objectSpanMethod, tableRowClassNameNew} from "@/utils/tool.js";
import {childShelfProductTableColum as titleList} from '../../../config'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  shelfDetail: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['update:modelValue'])
const {modelValue, shelfDetail} = toRefs(props)
const {pageInfo, childShelfProductList, getChildShelfProductList} = useChildShelf()
const {height} = useViewPort()

const openDia = async () => {
  await getChildShelfProductList({childShelfId: shelfDetail.value.childShelfId})
}
const closeDia = () => {
  emit('update:modelValue', false)
}

const title = computed(() => shelfDetail.value.childShelfName + '详情')
const handleCurrentChange = (currentPage) => {
  pageInfo.pageNumber = currentPage
  getChildShelfProductList({childShelfId: shelfDetail.value.childShelfId})
}
</script>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'childShelfProductModal',
})
</script>

<style lang="scss" scoped>
.child-shelf-product-modal {
  position: relative;
  height: calc(var(--h) * 1px - 200px);

  .mainPage {
    bottom: 0;
  }
}
</style>