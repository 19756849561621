import {reactive, ref} from "vue";
import message from "@utils/tool/message";
import {insStockList} from "@utils/api/inStock/inventory";
import {usePagination} from "@utils/tool/page";
import {resetStockGoodsList} from "@/utils/tool.js";

const searchQuery = {
    followTimeStatus: -1,
    supplierId: "",
    brandId: "",
    query: '',
    scattered: -1,
    classifyId: '',
};

export default function () {
    const childShelfProductList = ref([])
    const childShelfInfo = reactive({
        childShelfName: 0,
        childShelfId: '',
        status: false,
        shelfId: 0,
    })
    const setChildShelfInfo = (info) => {
        childShelfInfo.childShelfName = info.childShelfName
        childShelfInfo.childShelfId = info.childShelfId
    }
    const {pageInfo, reset} = usePagination()
    const getChildShelfProductList = async (query, callBack) => {
        //todo
        const {data, code, msg} = await insStockList({
            pageSize: pageInfo.pageSize,
            pageNumber: pageInfo.pageNumber,
            ...searchQuery,
            ...query,
        })
        if (code !== 0) {
            message.error(msg)
            return
        }
        let list = data?.list || []
        if (callBack) {
            callBack(list)
        }
        pageInfo.total = data?.total || 0
        childShelfProductList.value = resetStockGoodsList(list).map((item) => {
            return {
                childShelfProductCount: item.abnormalStock + item.normalStock,
                ...item,
            }
        })
    }
    return {
        childShelfProductList,
        getChildShelfProductList,
        pageInfo,
        reset,
        childShelfInfo,
        setChildShelfInfo
    }
}